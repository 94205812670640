import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Box, Button, Grid, Typography} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import perfilEmpleadoEnumerators from 'src/modules/perfilEmpleado/perfilEmpleadoEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import GestorDocumentalAutocompleteFormItem from 'src/view/gestorDocumental/autocomplete/GestorDocumentalAutocompleteFormItem';
import HabilidadAutocompleteFormItem from 'src/view/habilidad/autocomplete/HabilidadAutocompleteFormItem';
import PresupuestoAutocompleteFormItem from 'src/view/presupuesto/autocomplete/PresupuestoAutocompleteFormItem';
import PuestoAutocompleteFormItem from 'src/view/puesto/autocomplete/PuestoAutocompleteFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import TerceroAutocompleteFormItem from 'src/view/tercero/autocomplete/TerceroAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';
import TurnoLaboralAutocompleteFormItem from '../../turnoLaboral/autocomplete/TurnoLaboralAutocompleteFormItem';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.descripcion'), {
    required: true,
  }),
  nombre: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.nombre'), {}),
  apellidos: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.apellidos'), {}),
  fechaNacimiento: yupFormSchemas.date(i18n('entities.perfilEmpleado.fields.fechaNacimiento'), {}),
  codigo: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.codigo'), {}),
  usuario: yupFormSchemas.relationToOne(i18n('entities.perfilEmpleado.fields.usuario'), {
    required: false,
  }),
  direccion: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.direccion'), {}),
  ciudad: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.ciudad'), {}),
  region: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.region'), {}),
  pais: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.pais'), {}),
  codigoArea: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.codigoArea'), {}),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.ubicacionGoogle'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.perfilEmpleado.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.perfilEmpleado.fields.longitud'), {}),
  telefonoPrincipal: yupFormSchemas.integer(i18n('entities.perfilEmpleado.fields.telefonoPrincipal'), {}),
  telefonoSecundario: yupFormSchemas.integer(i18n('entities.perfilEmpleado.fields.telefonoSecundario'), {}),
  telefonoSMS: yupFormSchemas.integer(i18n('entities.perfilEmpleado.fields.telefonoSMS'), {}),
  presupuesto: yupFormSchemas.relationToOne(i18n('entities.perfilEmpleado.fields.presupuesto'), {}),
  puesto: yupFormSchemas.relationToOne(i18n('entities.perfilEmpleado.fields.puesto'), {}),
  firma: yupFormSchemas.images(i18n('entities.perfilEmpleado.fields.firma'), {
    max: 1,
    required: false,
  }),
  idiomas: yupFormSchemas.stringArray(i18n('entities.perfilEmpleado.fields.idiomas'), {}),
  thirdPartyProviders: yupFormSchemas.relationToMany(i18n('entities.perfilEmpleado.fields.thirdPartyProviders'), {}),
  documentosAdjuntos: yupFormSchemas.relationToMany(i18n('entities.perfilEmpleado.fields.documentosAdjuntos'), {}),
  habilidadesMedicion: yupFormSchemas.relationToMany(i18n('entities.perfilEmpleado.fields.habilidadesMedicion'), {}),
  turnoLaboral: yupFormSchemas.relationToOne(i18n('entities.perfilEmpleado.fields.turnoLaboral'), {}),
  ubicacion: yupFormSchemas.relationToMany(i18n('entities.perfilEmpleado.fields.ubicacion'), {}),
  company: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.company'), {}),
  registrationNumber: yupFormSchemas.string(i18n('entities.perfilEmpleado.fields.registrationNumber'), {}),
  contractType: yupFormSchemas.enumerator(i18n('entities.perfilEmpleado.fields.contractType'), {
    options: perfilEmpleadoEnumerators.contractType,
  }),
  contractStartDate: yupFormSchemas.date(i18n('entities.perfilEmpleado.fields.contractStartDate'), {}),
  gender: yupFormSchemas.enumerator(i18n('entities.perfilEmpleado.fields.gender'), {
    options: perfilEmpleadoEnumerators.gender,
  }),
  isContractor: yupFormSchemas.boolean(i18n('entities.perfilEmpleado.fields.isContractor'), {}),
  companies: yupFormSchemas.stringArray(i18n('entities.perfilEmpleado.fields.companies'), {}),
  laborHour: yupFormSchemas.integer(i18n('entities.perfilEmpleado.fields.laborHour'), {}),
});

function TabPanel(props) {
  const {children, value, index, simpleView, ...other} = props;

  return (
    <Box
      sx={
        {
          ...magicFlexBox,
          display: value !== index ? 'none' : 'flex',
        } as any
      }
      role="tabpanel"
      style={{width: '100%', marginTop: simpleView ? -30 : undefined}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={
            {
              ...magicFlexBox,
              overflow: 'auto',
            } as any
          }
          p={0}
          pt={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function PerfilEmpleadoForm(props) {
  const contractorsView = props.contractorsView;
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const isBlumar = useSelector(authSelectors.selectIsBlumar);

  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const [tabValue, setTabValue] = useState(0);

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      nombre: record.nombre,
      apellidos: record.apellidos,
      fechaNacimiento: record.fechaNacimiento ? moment(record.fechaNacimiento, 'YYYY-MM-DD') : null,
      nationality: record.nationality,
      codigo: record.codigo,
      usuario: record.usuario,
      direccion: record.direccion,
      ciudad: record.ciudad,
      region: record.region,
      pais: record.pais,
      codigoArea: record.codigoArea,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      telefonoPrincipal: record.telefonoPrincipal,
      telefonoSecundario: record.telefonoSecundario,
      telefonoSMS: record.telefonoSMS,
      presupuesto: record.presupuesto,
      puesto: record.puesto,
      laborHour: record.laborHour,

      firma: record.firma || [],
      idiomas: record.idiomas || [],
      thirdPartyProviders: record.thirdPartyProviders || [],
      documentosAdjuntos: record.documentosAdjuntos || [],
      habilidadesMedicion: record.habilidadesMedicion || [],
      turnoLaboral: record.turnoLaboral,
      ubicacion: record.ubicacion || userLocations || [],

      company: record.company,
      registrationNumber: record.registrationNumber,
      contractType: record.contractType,
      contractStartDate: record.contractStartDate ? moment(record.contractStartDate, 'YYYY-MM-DD') : null,
      gender: record.gender,
      isContractor: record.isContractor ? 'true' : 'false',
      companies: record.companies || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  function a11yProps(index) {
    return {
      id: `perfil-empleado-tab-${index}`,
      'aria-controls': `perfil-empleado-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {saveLoading, modal} = props;

  const getDescripcion = () => {
    const perfilEmpleado = form.getValues();

    let nombre = perfilEmpleado.nombre;
    let apellidos = perfilEmpleado.apellidos;

    let rut = perfilEmpleado.registrationNumber;
    let codigo = perfilEmpleado.codigo;

    let usuario = perfilEmpleado.usuario;
    let email = usuario?.email;

    if (!nombre && !apellidos && !rut && !codigo && !email) return '';

    let unico = email || rut || codigo;

    const descripcion = `${nombre}${apellidos ? ` ${apellidos}` : ''}${unico ? ` <${unico}>` : ''}`;
    form.setValue('descripcion', descripcion);
    return descripcion;
  };

  const isContractor = useWatch({
    control: form.control,
    name: 'isContractor',
    defaultValue: props.record?.isContractor,
  });

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
            } as any
          }>
          <Box
            sx={
              {
                ...magicFlexBox,
                flexGrow: 1,
                bgcolor: 'background.paper',
              } as any
            }>
            <Typography variant="h1" sx={{mb: 1}}>
              {getDescripcion()}
            </Typography>

            {/* <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/perfil-empleado/${props.record?.id}`}
                  size={140}
                />
              </div> */}

            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="perfil-empleado-wizard"
              textColor="secondary"
              indicatorColor="secondary"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
              }}>
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Ubicación" {...a11yProps(1)} />
              {/* <Tab label="Contacto" {...a11yProps(2)} /> */}
              {!contractorsView && <Tab label={contractorsView ? 'Empresa Contratista' : 'Terceros'} {...a11yProps(2)} />}
              {!contractorsView && <Tab label="Adjuntos" {...a11yProps(3)} />}
              {!contractorsView && <Tab label="Financiero" {...a11yProps(4)} />}
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                {!contractorsView && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    <UserAutocompleteFormItem
                      name="usuario"
                      disabled={props.disabled}
                      onChange={(value) => {
                        if (value) {
                          form.setValue('descripcion', value && value.label);
                          form.setValue('nombre', value && (value?.firstName || value.label));
                          form.setValue('apellidos', value && value?.lastName);
                        }
                      }}
                      label={i18n('entities.perfilEmpleado.fields.usuario')}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="nombre"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.nombre')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="apellidos"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.apellidos')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="registrationNumber"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.registrationNumber')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <DatePickerFormItem
                    name="fechaNacimiento"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.fechaNacimiento')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <SelectFormItem
                    name="gender"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.gender')}
                    options={perfilEmpleadoEnumerators.gender.map((value) => ({
                      value,
                      label: i18n(`entities.perfilEmpleado.enumerators.gender.${value}`),
                    }))}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <SelectFormItem
                    name="nationality"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.nationality')}
                    required={false}
                    options={perfilEmpleadoEnumerators.nationality.map((value) => ({
                      value,
                      label: i18n(`entities.perfilEmpleado.enumerators.nationality.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="laborHour"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.laborHour')}
                    required={false}
                  />
                </Grid>
                {!props.disabled && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="isContractor"
                      disabled={props.disabled}
                      label={i18n('entities.perfilEmpleado.fields.isContractor')}
                      options={perfilEmpleadoEnumerators.isContractor.map((value) => ({
                        value,
                        label: i18n(`entities.perfilEmpleado.enumerators.isContractor.${value}`),
                      }))}
                      required={false}
                    />
                  </Grid>
                )}
                {isBlumar && isContractor && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="companies"
                      disabled={props.disabled}
                      label={i18n('entities.perfilEmpleado.fields.companies')}
                      options={perfilEmpleadoEnumerators.companiesBlumar.map((value) => ({
                        value,
                        label: value,
                      }))}
                      mode="multiple"
                      required={false}
                      selectAllButton={false}
                    />
                  </Grid>
                )}
                {contractorsView && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    {/* {props.disabled && <TerceroListItem value={form.getValues().thirdPartyProviders} />} */}
                    <TerceroAutocompleteFormItem
                      name="thirdPartyProviders"
                      disabled={props.disabled}
                      label={i18n('entities.incident.contractors.contractorCompany')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                      noLink
                    />
                  </Grid>
                )}
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <SelectFormItem
                    name="contractType"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.contractType')}
                    options={perfilEmpleadoEnumerators.contractType.map((value) => ({
                      value,
                      label: i18n(`entities.perfilEmpleado.enumerators.contractType.${value}`),
                    }))}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <DatePickerFormItem
                    name="contractStartDate"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.contractStartDate')}
                    required={false}
                  />
                </Grid>
                {!contractorsView && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="telefonoPrincipal"
                      disabled={props.disabled}
                      label={i18n('entities.perfilEmpleado.fields.telefonoPrincipal')}
                      required={false}
                    />
                  </Grid>
                )}
                {!contractorsView && (
                  <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="company"
                      disabled={props.disabled}
                      label={i18n('entities.perfilEmpleado.fields.company')}
                      required={false}
                    />
                  </Grid>
                )}
                {!contractorsView && (
                  <>
                    <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                      <PuestoAutocompleteFormItem
                        name="puesto"
                        disabled={props.disabled}
                        label={i18n('entities.perfilEmpleado.fields.puesto')}
                        required={false}
                        showCreate={!props.modal}
                      />
                    </Grid>
                    <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                      <SelectFormItem
                        name="idiomas"
                        disabled={props.disabled}
                        label={i18n('entities.perfilEmpleado.fields.idiomas')}
                        options={perfilEmpleadoEnumerators.idiomas.map((value) => ({
                          value,
                          label: i18n(`entities.perfilEmpleado.enumerators.idiomas.${value}`),
                        }))}
                        required={false}
                        mode="multiple"
                      />
                    </Grid>
                    <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                      <HabilidadAutocompleteFormItem
                        name="habilidadesMedicion"
                        disabled={props.disabled}
                        label={i18n('entities.perfilEmpleado.fields.habilidadesMedicion')}
                        required={false}
                        showCreate={!props.modal}
                        mode="multiple"
                      />
                    </Grid>
                    <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                      <TurnoLaboralAutocompleteFormItem
                        name="turnoLaboral"
                        disabled={props.disabled}
                        label={i18n('entities.perfilEmpleado.fields.turnoLaboral')}
                        required={false}
                        showCreate={!props.modal}
                        // mode="multiple"
                      />
                    </Grid>
                    <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                      <InputFormItem
                        name="codigo"
                        disabled={props.disabled}
                        label={i18n('entities.perfilEmpleado.fields.codigo')}
                        required={false}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <UbicacionAutocompleteFormItem
                  name="ubicacion"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.ubicacion')}
                  required={false}
                  showCreate={false}
                  mode="multiple"
                />
              </Grid>
              {/* <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <GoogleAutocompleteFormItem
                  name="ubicacionGoogle"
                  disabled={props.disabled}
                  label={i18n('entities.ubicacion.fields.ubicacionGoogle')}
                  required={false}
                  defaultCenter={{
                    lat: -36.8269882,
                    lng: -73.0497665,
                  }}
                  center={{
                    lat: form.watch('latitud'),
                    lng: form.watch('longitud'),
                  }}
                  zoom={14}
                  enableMap={true}
                />
              </Grid> */}
              <h2>Dirección Manual</h2>
              <hr />
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputFormItem
                  name="direccion"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.direccion')}
                  required={false}
                />
              </Grid>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputFormItem
                  name="ciudad"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.ciudad')}
                  required={false}
                />
              </Grid>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputFormItem
                  name="region"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.region')}
                  required={false}
                />
              </Grid>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputFormItem
                  name="pais"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.pais')}
                  required={false}
                />
              </Grid>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputFormItem
                  name="codigoArea"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.codigoArea')}
                  required={false}
                />
              </Grid>
            </TabPanel>

            {/* <TabPanel value={tabValue} index={2}>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputNumberFormItem
                  name="telefonoSecundario"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.telefonoSecundario')}
                  required={false}
                />
              </Grid>
              <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                <InputNumberFormItem
                  name="telefonoSMS"
                  disabled={props.disabled}
                  label={i18n('entities.perfilEmpleado.fields.telefonoSMS')}
                  required={false}
                />
              </Grid>
            </TabPanel> */}

            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="firma"
                disabled={props.disabled}
                label={i18n(
                  'entities.perfilEmpleado.fields.firma',
                )}
                required={false}
                storage={Storage.values.perfilEmpleadoFirma}
                max={1}
              />
            </Grid> */}
            {!contractorsView && (
              <TabPanel value={tabValue} index={2}>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <TerceroAutocompleteFormItem
                    name="thirdPartyProviders"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.thirdPartyProviders')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              </TabPanel>
            )}
            {!contractorsView && (
              <TabPanel value={tabValue} index={3}>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <GestorDocumentalAutocompleteFormItem
                    name="documentosAdjuntos"
                    label={i18n('entities.perfilEmpleado.fields.documentosAdjuntos')}
                    disabled={props.disabled}
                    required={true}
                    showCreate={!props.modal}
                    defaultprops={{tipo: 'Imagen'}}
                    showButton={true}
                    mode="multiple"
                    disableAutocomplete
                  />
                </Grid>
              </TabPanel>
            )}

            {!contractorsView && (
              <TabPanel value={tabValue} index={4}>
                <Grid item lg={props.disabled ? 6 : 10} md={8} sm={12} xs={12}>
                  <PresupuestoAutocompleteFormItem
                    name="presupuesto"
                    disabled={props.disabled}
                    label={i18n('entities.perfilEmpleado.fields.presupuesto')}
                    required={false}
                    showCreate={!props.modal}
                  />
                </Grid>
              </TabPanel>
            )}
          </Box>
          {!props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
              }}>
              <Button
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
                size="small">
                {i18n('common.save')}
              </Button>

              <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </FormButtons>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default PerfilEmpleadoForm;
