import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Box, Button, CircularProgress, Grid, TextField} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import OpenAIService from 'src/modules/shared/openai/openAIService';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import CategoriaAutocompleteFormItem from 'src/view/categoria/autocomplete/CategoriaAutocompleteFormItem';
import ModeloPrincipalFormularioForm from 'src/view/modeloFormulario/form/ModeloPrincipalFormularioForm';
import ModeloTareaForm from 'src/view/modeloTarea/form/ModeloTareaForm';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SwitchFormItem from 'src/view/shared/form/items/SwitchFormItem';
import Message from 'src/view/shared/message';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';
import modeloFormularioEnumerators from '../../../modules/modeloFormulario/modeloFormularioEnumerators';
import RadioFormItem from '../../shared/form/items/RadioFormItem';

import Resizer from 'react-image-file-resizer';

const schema = yup.object().shape({
  clasificacion: yupFormSchemas.enumerator(i18n('entities.tablaCostoEnergia.fields.clasificacion'), {
    options: modeloFormularioEnumerators.clasificacion,
  }),
  nombre: yupFormSchemas.string(i18n('entities.modeloFormulario.fields.nombre'), {
    required: true,
  }),
  sucursales: yupFormSchemas.relationToMany(i18n('entities.modeloFormulario.fields.sucursales'), {
    required: false,
  }),
  formato: yupFormSchemas.generic(i18n('entities.modeloFormulario.fields.formato')),
  categoriaGeneral: yupFormSchemas.relationToOne(i18n('entities.modeloFormulario.fields.categoriaGeneral'), {
    required: true,
  }),
  categoriasFormulario: yupFormSchemas.relationToMany(i18n('entities.modeloFormulario.fields.categoriasFormulario'), {}),
  habilidades: yupFormSchemas.relationToMany(i18n('entities.modeloFormulario.fields.habilidades'), {
    required: false,
  }),
  crearTarea: yupFormSchemas.boolean('Crear Tarea', {}),
  tareaData: yupFormSchemas.generic('Datos tarea'),
});

function ModeloFormularioForm(props) {
  const [valid, setValid] = useState<any>(false);
  const [initialValues] = useState(() => {
    const record = props.record || {};

    const formatoDefault = record.formato?.length
      ? record.formato
      : [{categoria: '', isDynamic: false, subseccions: [{categoria: '', isDynamic: false}]}];
    return {
      nombre: record.nombre,
      clasificacion: record.clasificacion || 'operativa',
      sucursales: record.sucursales || [],
      categoriaGeneral: record.categoriaGeneral,
      categoriasFormulario: record.categoriasFormulario || [],
      habilidades: record.habilidades || [],
      formato: formatoDefault,
      crearTarea: false,
      tareaData: {},
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  useEffect(() => {
    pushHistory({messagesToShow: messages, localFormat: initialValues.formato});
  }, []);

  const tipoRespuestaIncidenteOptions = [
    {
      value: 'incident_incidentCode',
      label: i18n('entities.incident.fields.incidentCode') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_withLostDays',
      label: i18n('entities.incident.fields.withLostDays') + ` (${i18n('entities.incident.name')})`,
      responseType: 'checkbox',
    },
    {
      value: 'incident_initialClassification',
      label: i18n('entities.incident.fields.initialClassification') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
    },
    {
      value: 'incident_finalClassification',
      label: i18n('entities.incident.fields.finalClassification') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
    },
    {
      value: 'incident_severity',
      label: i18n('entities.incident.fields.severity') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
    },
    {
      value: 'incident_label',
      label: i18n('entities.incident.fields.label') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_dateFrom',
      label: i18n('entities.incident.fields.dateFrom') + ` (${i18n('entities.incident.name')})`,
      responseType: 'date',
    },
    {
      value: 'incident_dateFromTime',
      label: i18n('entities.incident.fields.dateFromTime') + ` (${i18n('entities.incident.name')})`,
      responseType: 'time',
    },
    {
      value: 'incident_dateTo',
      label: i18n('entities.incident.fields.dateTo') + ` (${i18n('entities.incident.name')})`,
      responseType: 'date',
    },
    {
      value: 'incident_responsibleAdvisor',
      label: i18n('entities.incident.fields.responsibleAdvisor') + ` (${i18n('entities.incident.name')})`,
      responseType: 'perfilEmpleado',
    },
    {
      value: 'incident_incidentLocation',
      label: i18n('entities.incident.fields.incidentLocation') + ` (${i18n('entities.incident.name')})`,
      responseType: 'location',
    },
    {
      value: 'incident_incidentDescription',
      label: i18n('entities.incident.fields.incidentDescription') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_narrativeAccount',
      label: i18n('entities.incident.fields.narrativeAccount') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_incidentStatus',
      label: i18n('entities.incident.fields.incidentStatus') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
    },
    {
      value: 'incident_company',
      label: i18n('entities.incident.fields.company') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_employees',
      label: i18n('entities.incident.fields.employee') + ` (${i18n('entities.incident.name')})`,
      responseType: 'perfilEmpleado',
    },
    {
      value: 'incident_contractType',
      label: i18n('entities.incident.fields.contractType') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
  ];

  const fakeResponseTypeToNormal = (formato: any[] = []) => {
    formato.forEach((seccion: any) => {
      seccion.subseccions.forEach((subseccion: any) => {
        subseccion.preguntas.forEach((pregunta: any) => {
          const tipoRespuestaIncidente = tipoRespuestaIncidenteOptions.find(
            (tipoRespuestaIncidente) => tipoRespuestaIncidente.value === pregunta.tipoRespuesta,
          );
          if (tipoRespuestaIncidente) {
            pregunta.tipoRespuesta = tipoRespuestaIncidente.responseType;
            pregunta.keyPointer = tipoRespuestaIncidente.value.split('_')[1];
          }
        });
      });
    });
    return formato;
  };

  const onSubmit = (values) => {
    const formato = values['formato'] || [];
    if (values['clasificacion'] === 'incidente') {
      values['formato'] = fakeResponseTypeToNormal(formato);
    }
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  const [isOpenExitConfirmModal, setIsOpenExitConfirmModal] = useState(false);

  const doOpenExitConfirmModal = () => {
    setIsOpenExitConfirmModal(true);
  };

  const doCloseExitConfirmModal = () => {
    setIsOpenExitConfirmModal(false);
  };

  const doExit = () => {
    doCloseExitConfirmModal();
    props.onCancel();
  };
  //const watchClasificacion = useWatch({ name: 'clasificacion', control: form.control });
  //const watchFormato = useWatch({ name: 'formato', control: form.control }); // esto explota, solo descomentar en debug

  const clasificacionValue = useWatch({name: 'clasificacion', control: form.control, defaultValue: 'operativa'});
  //let formatoValue = form.getValues('formato');

  const userRoles = useSelector(authSelectors.selectRoles);
  const hasAccessToIA = userRoles.includes('accesoIA');

  const user = useSelector(authSelectors.selectCurrentUser);
  const userName = user?.fullName || user?.email;

  const saveDataTarea = (id, data) => {
    console.log('%c⧭ saveDataTarea', 'color: #735656', {data});
    form.setValue('tareaData', data);
    setValid(true);
  };
  const editDataTarea = () => {
    form.setValue('tareaData', {});
    setValid(false);
  };

  const formValues = form.getValues();
  //console.log('%c⧭', 'color: #408059', {formValues});

  // const [expanded, setExpanded] = React.useState<string | false>(false); //fields?.[0]?.id);

  // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [loadingIA, setLoadingIA] = useState(false);
  const validAnswerTypes = [
    'checkbox',
    'checkBoxList',
    'selection',
    'yesNo',
    'date',
    'time',
    'textField',
    'numberField',
    'inspectionCheck',
    'location',
    'ammount',
    'registrationNumber',
    'barcode',
    'day',
    'month',
    'year',
    'thirdParty',
    'perfilEmpleado',
    'asset',
    'files',
    'signature',
    'meterReading',
  ];
  const optionalSettings = [
    'allowDateBefore',
    'haveCriticality',
    'havePhotos',
    'haveFiles',
    'tieneComentario',
    'allowOthersOption',
    'isRequired',
  ];

  const systemExample = {
    sections: [
      {
        name: 'Seguridad',
        sectionIndex: 0,
        subsections: [
          {
            name: 'Inspección',
            subsectionIndex: 0,
            questions: [
              {
                name: 'fechaInspeccion',
                label: 'Fecha de Inspección',
                answerType: 'date',
                required: true,
              },
              {
                name: 'areaInspeccionada',
                label: 'Área Inspeccionada',
                answerType: 'text',
                required: true,
              },
              {
                name: 'condicionEquipo',
                label: 'Condición del Equipo',
                answerType: 'inspectionCheck',
                required: true,
              },
              {
                name: 'observaciones',
                label: 'Observaciones',
                answerType: 'text',
              },
            ],
          },
        ],
      },
    ],
    systemMessage: `¡Claro! Vamos a convertir este formulario en una estructura digital utilizando el formato Planiwise.

Análisis del formulario:

    (Escribe un análisis del formulario, si es necesario).

Estructura propuesta en Planiwise:

1. Sección de Control de Proceso:

    (Acá especificar las preguntas generales de la sección no dinámica, por ejemplo: Fecha, Hora, Área, etc.)

2. Subsección de Enfriado (Tabla Dinámica):

    Subcategoría (Dinámica): "Registro de Enfriado" (se replicará para cada registro horario).
    Columnas (Preguntas):
        (Acá debes especificar las preguntas de la tabla, por ejemplo: Hora, Temperatura, Observaciones, etc.)

3. Pie de formulario:

    (Espeficiar si hay un pie de formulario con observaciones finales o aclaraciones).

Consideraciones:

    (Listar las consideraciones)`,
  };
  const systemMessage = () => {
    const exampleQuestionarie = JSON.stringify(systemExample);
    return {
      role: 'system',
      content: [
        {
          type: 'text',
          text: `Eres un asistente creado y desarrollado por una empresa de software web llamada Planiwise, usando tecnología de OpenAI. 
Estás ayudando a generar formularios en español y en formato JSON. Deben una estructura similar al ejemplo que te dejaré más abajo. Nunca dejes secciones o atributos vacíos.

'answerType' debe ser exactamente uno de estos: ${validAnswerTypes.join(', ')}. No se permiten otros tipos de respuesta.
'multipleChoice', 'checkBoxList' y 'selection' requieren un array 'options' con las alternativas.
'ammount' es para dinero.
'inspectionCheck' permite seleccionar entre Aprobado/Observado/Rechazado.
'rut' es para el RUT de una persona.
'barcode' es para códigos de barra.
'date', 'time', 'day', 'month' y 'year' son para fechas y horas.
'asset' es para activos (que son equipos, máquinas o motorizados).
'location' es para ubicaciones.
'thirdParty' es para terceros.
'perfilEmpleado' es para empleados o colaboradores (trabajadores que se crean en un módulo de rrhh)
'files' es para documentos adjuntos.
'meterReading' es para lecturas de medidores.

'textFromImage' es para texto extraído de una imagen mediante IA.
'signature' es para firmas electrónicas.

Las preguntas pueden tener los siguientes settings booleanos: ${optionalSettings.join(', ')}.
'required' indica si la pregunta es obligatoria.
'allowDateBefore' (permitir fechas antiguas) solo para 'date'.
'allowOthersOption' (permitir opcion 'otros') solo para 'multipleChoice' y 'checkBoxList'.

Los nombres de los campos (name) deben ser únicos dentro del formulario.

También tenemos la opción de crear formularios dinámicos, para esto la subsection debe tener 'isDynamic: true'.
'isDynamic' se utiliza para subsections y permite la repetición de un conjunto de preguntas. 
Por ejemplo una tabla se puede expresar como una subsection isDynamic:true donde cada pregunta sería una columna de la tabla.

'systemMessage' es para responder al usuario sobre su solicitud de generación del formulario.

¿Te parece si primero analizas bien el contenido del formulario como en el ejemplo y después generas el JSON?

JSON DE EJEMPLO PARA QUE ENTIENDAS LA ESTRUCTURA: 
"${exampleQuestionarie}"
`,
        },
      ],
    };
  };

  const [messages, setMessages] = useState<any>([
    {
      role: 'assistant',
      content: `¡Hola! Soy tu asistente para la creación de procedimientos. Te ayudaré a crearlos en base a lo que me pidas. Un ejemplo de solicitud es: "Quiero un formulario para registrar incidentes".`,
    },
  ]);
  const [formatHistory, setFormatHistory] = useState<any>([]);
  const [historyIndex, setHistoryIndex] = useState<any>(0);

  const messageRef = useRef<any>();
  //const html = (strings, ...values) => String.raw({raw: strings}, ...values);

  const handleSendMessage = async (event) => {
    event.preventDefault();

    if (loadingIA) return;

    // get message from input
    const message = messageRef.current.value;
    if (!message) return;

    // get current format
    const currentFormat = form.getValues('formato');
    const openAIFormat = convertToOpenAIFormat(currentFormat);

    // add user message
    const messagesToSend = [
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: `Formulario actual: ${JSON.stringify(openAIFormat)}.\nMi solicitud es: "${message}" (Responder en json)`,
          },
        ],
      },
    ];
    const fotosAdjuntas = form.getValues('fotosAdjuntas');
    let imageUrl: any = null;
    if (fotosAdjuntas && fotosAdjuntas.length > 0) {
      imageUrl = await urlToBase64(fotosAdjuntas[0].downloadUrl);
      messagesToSend[0].content.push({
        type: 'image_url',
        image_url: {
          url: imageUrl,
        },
      } as any);
    }

    // find last assistant message
    const lastAssistantMessage = messages.findLast((message) => message.role === 'assistant');
    if (lastAssistantMessage) {
      // add last assistant message to new messages
      messagesToSend.unshift(lastAssistantMessage);
    }

    const userMessage = {role: 'user', content: message};

    // set messages to show
    setMessages([
      userMessage,
      {
        role: 'assistant',
        content: `Procesando solicitud...`,
      },
    ]);

    // clear input
    messageRef.current.value = '';

    // request chat completion
    messagesToSend.unshift(systemMessage());

    requestChatCompletion({messagesToSend, userMessage, openAIFormat});
  };

  const urlToBase64 = async (url) => {
    try {
      const response = await axios.get(url + '?v=' + (Math.floor(Math.random() * 10) + 1), {responseType: 'blob'});
      const resizedImg = await resizeFile(response.data);
      return resizedImg;
    } catch (error) {
      console.log('urlToBase64 error', {error});
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=';
    }
  };
  const resizeFile = (file) => {
    console.log('%c⧭', 'color: #86bf60', 'RESIZING!');
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2048,
        2048,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
      );
    });
  };

  const regenerateCompletion = () => {
    const openAIFormat = formatHistory[historyIndex].openAIFormat;
    const messagesToSend = formatHistory[historyIndex].messagesToSend;
    const userMessage = formatHistory[historyIndex].userMessage;
    requestChatCompletion({messagesToSend, userMessage, openAIFormat});
  };

  const requestChatCompletion = async ({messagesToSend, userMessage, openAIFormat}) => {
    if (loadingIA) return;
    setLoadingIA(true);
    let response;
    try {
      // get result from openAI
      response = await OpenAIService.chatCompletion(messagesToSend);
    } catch (e) {
      console.log(e);
      Message.error('Error al conectar con servicio de IA');
    }
    let messagesToShow = [userMessage];
    if (response?.content) {
      // parse response
      const parsed = JSON.parse(response.content);

      // add system message to new messages
      messagesToShow.push({
        role: 'assistant',
        content: parsed?.systemMessage || '',
      });
      // convert to local format
      const localFormat = convertToLocalFormat(parsed);
      console.log({response, parsed, localFormat});

      // save new format in history
      pushHistory({localFormat, messagesToShow, messagesToSend, userMessage, openAIFormat});

      // set new format
      if (localFormat) form.setValue('formato', localFormat);
    } else {
      // (no content in response) TODO: better handle for errors
      // find last assistant message
      const lastAssistantMessage = messages.findLast((message) => message.role === 'assistant');
      if (lastAssistantMessage) {
        // set error message
        lastAssistantMessage.content = 'Error al conectar con servicio de IA';
        messagesToShow.push(lastAssistantMessage);
      }
      // save message in history
      pushHistory({localFormat: form.getValues('formato'), messagesToShow, messagesToSend, userMessage, openAIFormat});
    }
    // set messages
    setMessages(messagesToShow);
    setLoadingIA(false);
  };

  const pushHistory = ({
    localFormat = [],
    messagesToShow = [],
    messagesToSend = [],
    userMessage = null,
    openAIFormat = [],
  }: any) => {
    const newHistory = {
      localFormat,
      messagesToShow,
      messagesToSend,
      userMessage,
      openAIFormat,
    };

    let newFormatHistory = [...formatHistory];

    // if historyIndex is not at the end, remove all history after it
    if (historyIndex < formatHistory.length - 1) {
      newFormatHistory = newFormatHistory.slice(0, historyIndex + 1);
    }

    // push new history
    newFormatHistory.push(newHistory);

    // set new history
    setFormatHistory(newFormatHistory);
    setHistoryIndex(newFormatHistory.length - 1);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleUndo = async () => {
    if (historyIndex > 0) {
      // set history index
      let newHistoryIndex = historyIndex - 1;
      setHistoryIndex(newHistoryIndex);

      // get history at index
      const history = formatHistory[newHistoryIndex];

      // loading for rerendering purposes
      setLoadingIA(true);
      await sleep(500);

      // set format and messages
      form.setValue('formato', history.localFormat);
      setMessages(history.messagesToShow);

      setLoadingIA(false);
    }
  };

  const handleRedo = async () => {
    if (historyIndex < formatHistory.length - 1) {
      // set history index
      let newHistoryIndex = historyIndex + 1;
      setHistoryIndex(newHistoryIndex);

      // get history at index
      const history = formatHistory[newHistoryIndex];

      // loading for rerendering purposes
      setLoadingIA(true);
      await sleep(500);

      // set format and messages
      form.setValue('formato', history.localFormat);
      setMessages(history.messagesToShow);

      setLoadingIA(false);
    }
  };

  const convertToOpenAIFormat = (formato) => {
    const sections = formato.map((seccion, sectionIndex) => {
      return {
        name: seccion.categoria,
        sectionIndex,
        subsections: seccion.subseccions.map((subseccion, subsectionIndex) => {
          return {
            name: subseccion.categoria,
            subsectionIndex,
            isDynamic: subseccion.isDynamic,
            questions: subseccion.preguntas.map((pregunta, questionIndex) => {
              let value: any = {
                name: pregunta.pregunta.titulo,
                questionIndex,
                answerType: pregunta.tipoRespuesta,
              };
              if (pregunta.tipoRespuesta === 'multipleChoice') {
                value.options = pregunta.alternativas ? pregunta.alternativas.map((alternativa) => alternativa.label) : [];
                value.answerType = 'selection';
              }
              if (pregunta.tipoRespuesta === 'multiCheckbox') {
                value.options = pregunta.alternativasCheckbox?.map((alternativa) => alternativa.label) || [];
                value.answerType = 'checkBoxList';
              }
              return value;
            }),
          };
        }),
      };
    });
    return {sections};
  };

  const createOptionLabelId = (option, index) => ({
    label: option.label || option,
    id: `${index}-${option.value || option.toLowerCase().replace(/\W/g, '')}`,
  });

  const convertToLocalFormat = (openAIFormat) => {
    return openAIFormat.sections?.map((section) => {
      return {
        categoria: section.name,
        isDynamic: false,
        subseccions: section.subsections?.map((subsection) => {
          return {
            categoria: subsection.name,
            isDynamic: subsection.isDynamic,
            preguntas: subsection.questions?.map((question) => {
              let value: any = {
                pregunta: {
                  titulo: question.label || question.name,
                  label: question.label || question.name,
                },
                tipoRespuesta: question.answerType,
              };
              optionalSettings.forEach((setting) => {
                if (setting in question) {
                  value[setting] = question[setting];
                }
              });
              if (question.answerType === 'checkBoxList') {
                value.alternativasCheckbox = question.options?.map(createOptionLabelId);
                value.tipoRespuesta = 'multiCheckbox';
              }
              if (question.answerType === 'selection') {
                value.alternativas = question.options?.map(createOptionLabelId);
                value.tipoRespuesta = 'multipleChoice';
              }
              console.log('%c⧭', 'color: #735656', {question, value});
              return value;
            }),
          };
        }),
      };
    });
  };

  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    const chatContainer = chatContainerRef?.current;
    if (!chatContainer) return;
    setTimeout(() => {
      chatContainer.scrollTo({
        top: window.innerHeight - 200,
        behavior: 'smooth',
      });
    }, 300);
  }, [messages]);

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: '18px',
            //maxWidth: 'min-content',
          }}>
          <Box>
            <Grid container spacing={2} style={{paddingLeft: '10px'}}>
              <Grid item lg={6} md={6} sm={6} xs={11}>
                <InputFormItem
                  shrinkLabel={false}
                  name="nombre"
                  label={i18n('entities.modeloFormulario.fields.nombre')}
                  required={true}
                  autoFocus
                />
              </Grid>
              {/*
                <Grid item lg={12} md={8} sm={12} xs={12}>
                  <SucursalAutocompleteFormItem
                    name="sucursales"
                    label={i18n('entities.modeloFormulario.fields.sucursales')}
                    required={true}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              */}

              <Grid item lg={5} md={5} sm={5} xs={11}>
                <CategoriaAutocompleteFormItem
                  name="categoriaGeneral"
                  label={i18n('entities.modeloFormulario.fields.categoriaGeneral')}
                  required={false}
                  showCreate={!props.modal}
                />
              </Grid>

              {/* <Grid item lg={12} md={8} sm={12} xs={12}>
                <HabilidadAutocompleteFormItem
                  name="habilidades"
                  label={i18n('entities.modeloFormulario.fields.habilidades')}
                  required={true}
                  showCreate={!props.modal}
                  mode="multiple"
                />
              </Grid> */}

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <RadioFormItem
                  name="clasificacion"
                  label={i18n('entities.modeloFormulario.fields.clasificacion')}
                  options={modeloFormularioEnumerators.clasificacion.map((value) => ({
                    value,
                    label: i18n(`entities.modeloFormulario.enumerators.clasificacion.${value}`),
                  }))}
                  required={false}
                  defaultValue={props.record?.clasificacion || 'operativa'}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={{minHeight: 110, paddingRight: '16px'}}>
                {
                  <SwitchFormItem
                    label="Crear Tarea"
                    name="crearTarea"
                    onChange={(value) => {
                      !value && editDataTarea();
                    }}
                  />
                }
                {formValues.crearTarea && (
                  <ModeloTareaForm
                    defaultValues={{
                      titulo: formValues.nombre,
                      tipoProcedimiento: 'Evaluacion',
                      tipoVisualizacion: 'Procedimiento',
                      prioridad: 'Alta',
                    }}
                    simpleView={true}
                    saveLoading={saveLoading}
                    isEditing={false}
                    hideFormWhenReady={true}
                    onSubmit={saveDataTarea}
                    onCancel={editDataTarea}
                    disabled={valid}
                  />
                )}
              </Grid>
            </Grid>
            {hasAccessToIA && (
              <Grid item lg={7} md={7} sm={7} xs={12} style={{padding: 10, marginBottom: 48}}>
                <Grid
                  container
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 5,
                    padding: 10,
                    //backgroundColor: '#fffbe6',
                  }}>
                  <Grid
                    item
                    xs={12}
                    container
                    style={{
                      alignItems: 'center',
                      padding: 16,
                      paddingRight: 0,
                    }}>
                    <Grid item xs>
                      <TextField
                        multiline
                        rows={0}
                        variant="outlined"
                        fullWidth
                        name="message"
                        placeholder="Escribe tu solicitud"
                        inputRef={messageRef}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleSendMessage(event);
                          }
                        }}
                      />{' '}
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={loadingIA}
                        color="primary"
                        style={{marginLeft: 8}}
                        type="button"
                        onClick={handleSendMessage}>
                        Enviar
                      </Button>
                      {/* {loadingIA && <CircularProgress size={25} style={{marginLeft: 8}} />} */}
                    </Grid>
                    <Grid item pl={1}>
                      <ImagesFormItem
                        name="fotosAdjuntas"
                        disabled={props.disabled}
                        label={i18n('entities.modeloTarea.fields.fotosAdjuntas')}
                        required={false}
                        storage={Storage.values.modeloTareaFotosAdjuntas}
                        max={1}
                      />
                    </Grid>
                  </Grid>
                  {messages?.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        placeContent: 'start',
                        overflowY: 'scroll',
                        maxHeight: 'calc(100vh - 200px)',
                        overflowX: 'hidden',
                        padding: 16,
                        paddingRight: 54,
                      }}>
                      {messages
                        .filter((m) => m.role !== 'system')
                        .slice(-2)
                        .map((message, index) => (
                          <div
                            key={index}
                            style={{
                              margin: '10px 0',
                              padding: '10px',
                              border: '1px solid #ddd',
                              borderRadius: '10px',
                              backgroundColor: message.role === 'user' ? 'rgb(63, 81, 181, 0.2)' : '#fffbe6',
                            }}>
                            <strong>{message.role === 'user' ? userName : 'Planiwise AI Assistant'}</strong>
                            <hr style={{opacity: 0.3}} />
                            <ReactMarkdown>{message.content}</ReactMarkdown>
                          </div>
                        ))}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 6,
                    }}>
                    <div style={{display: 'flex' /*, justifyContent: 'space-between'*/}}>
                      <Button
                        color="primary"
                        style={{marginLeft: 8}}
                        type="button"
                        onClick={handleUndo}
                        disabled={loadingIA || historyIndex === 0}>
                        Deshacer
                      </Button>
                      <Button
                        color="primary"
                        style={{marginLeft: 8}}
                        type="button"
                        onClick={handleRedo}
                        disabled={loadingIA || historyIndex >= formatHistory.length - 1}>
                        Rehacer
                      </Button>
                      <Button
                        color="primary"
                        style={{marginLeft: 'auto'}}
                        type="button"
                        onClick={regenerateCompletion}
                        disabled={loadingIA || historyIndex === 0}>
                        Regenerar
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{paddingLeft: 10, paddingRight: 40}}>
                {loadingIA && (
                  <div style={{textAlign: 'center'}}>
                    <CircularProgress />
                  </div>
                )}
                {!loadingIA && <ModeloPrincipalFormularioForm clasificacion={clasificacionValue} name="formato" />}
              </div>
            </Grid>
          </Box>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'Grid-reverse' : undefined,
            placeContent: 'space-between',
            maxWidth: '330px',
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.save')}
          </Button>

          <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button disabled={saveLoading} onClick={doOpenExitConfirmModal} type="button" startIcon={<CloseIcon />} size="small">
              {i18n('common.exit')}
            </Button>
          ) : null}
        </FormButtons>
        {isOpenExitConfirmModal && (
          <ConfirmModal
            title={i18n('common.areYouSure')}
            onConfirm={doExit}
            onClose={() => doCloseExitConfirmModal()}
            okText={i18n('common.yes')}
            cancelText={i18n('common.no')}
          />
        )}
      </FormProvider>
    </FormWrapper>
  );
}

export default ModeloFormularioForm;
