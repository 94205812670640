import {yupResolver} from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import RecursoFormModal from 'src/view/recurso/form/RecursoFormModal';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import TipoPresupuestoAutocompleteFormItem from 'src/view/tipoPresupuesto/autocomplete/TipoPresupuestoAutocompleteFormItem';

import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.presupuesto.fields.descripcion'), {
    required: true,
  }),
  tipoPresupuesto: yupFormSchemas.relationToOne(i18n('entities.presupuesto.fields.tipoPresupuesto'), {}),
  montoPresupuesto: yupFormSchemas.integer(i18n('entities.presupuesto.fields.montoPresupuesto'), {
    required: true,
  }),
  cuentasContables: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.cuentasContables'), {}),
  fechainicio: yupFormSchemas.date(i18n('entities.presupuesto.fields.fechainicio'), {}),
  fechaFin: yupFormSchemas.date(i18n('entities.presupuesto.fields.fechaFin'), {}),
  habilitado: yupFormSchemas.boolean(i18n('entities.presupuesto.fields.habilitado'), {}),
  puestosVinculados: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.puestosVinculados'), {}),
  centrosCostosVinculados: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.centrosCostosVinculados'), {}),
  recursosVinculados: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.recursosVinculados'), {}),
});

function PresupuestoForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      tipoPresupuesto: record.tipoPresupuesto,
      montoPresupuesto: record.montoPresupuesto,
      cuentasContables: record.cuentasContables || [],
      fechainicio: record.fechainicio ? moment(record.fechainicio, 'YYYY-MM-DD') : null,
      fechaFin: record.fechaFin ? moment(record.fechaFin, 'YYYY-MM-DD') : null,
      habilitado: record.habilitado,
      puestosVinculados: record.puestosVinculados || [],
      centrosCostosVinculados: record.centrosCostosVinculados || [],
      recursosVinculados: record.recursosVinculados || [],
    };
  });
  const [resourcesGrouped, setResourcesGrouped] = useState<any>({
    perfilEmpleado: [],
    inventario: [],
    servicios: [],
    otros: [],
  });
  const [openRecursoModal, setOpenRecursoModal] = useState(false);
  const [editingRecurso, setEditingRecurso] = useState(null);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const recursosVinculados = useWatch({
    control: form.control,
    name: 'recursosVinculados',
  });

  const {saveLoading, modal} = props;

  useEffect(() => {
    // 1. Creamos un objeto nuevo para la agrupación
    const newGroups: any = {
      perfilEmpleado: [],
      inventario: [],
      servicios: [],
      otros: [],
    };

    // 2. Recorremos los recursos y los vamos agrupando
    recursosVinculados.forEach((recurso) => {
      switch (recurso.tipo) {
        case 'Recurso Humano':
          newGroups.perfilEmpleado.push(recurso);
          break;
        case 'Inventario':
          newGroups.inventario.push(recurso);
          break;
        case 'Servicios':
          newGroups.servicios.push(recurso);
          break;
        default:
          newGroups.otros.push(recurso);
          break;
      }
    });

    setResourcesGrouped(newGroups);
  }, [recursosVinculados]);

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const handleOpenRecursoModal = (recurso = null) => {
    setEditingRecurso(recurso);
    setOpenRecursoModal(true);
  };

  const handleCloseRecursoModal = () => {
    setOpenRecursoModal(false);
    setEditingRecurso(null);
  };

  const handleAddRecurso = (recurso) => {
    const recursoExistente = recursosVinculados.find((r) => r.id === recurso.id);
    if (recursoExistente) {
      const newArray = recursosVinculados.map((r) => (r.id === recurso.id ? recurso : r));
      form.setValue('recursosVinculados', newArray);
      return;
    }

    console.log('recurso', recurso);
    console.log('recursosVinculados', recursosVinculados);

    form.setValue('recursosVinculados', [...recursosVinculados, recurso]);
  };

  // const handleUpdateRecurso = (recurso) => {
  //   const oldArray = form.getValues('recursosVinculados');
  //   const newArray = oldArray.map((r) => (r.id === recurso.id ? recurso : r));
  //   form.setValue('recursosVinculados', newArray);
  // };

  // const handleAddRecurso = (recurso) => {
  //   form.setValue('recursosVinculados', [...recursosVinculados, recurso]);
  // };

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            paddingBottom: '16px',
          }}>
          <Grid spacing={2} container>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h1>{form.watch('descripcion')}</h1>
            </Grid>

            {/* Sección de recursos + botón Agregar */}
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <h2 style={{margin: 0}}>Detalle de Recursos</h2>

                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => handleOpenRecursoModal(null)}>
                  {i18n('common.new')}
                </Button>
              </div>
            </Grid>

            {/* 2. Mostrar recursos agrupados en distintos accordions */}
            <Accordion defaultExpanded style={{width: '100%', marginTop: 10}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-humano"
                id="panel-humano-header"
                style={{border: '1px solid #f0f0f0'}}>
                <Typography>Recursos Humanos</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} style={{width: '100%'}}>
                  <Table aria-label="recursos-humanos-table">
                    <TableHead>
                      <TableRow style={{padding: 30}}>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Puesto</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>{i18n('entities.perfilEmpleado.fields.laborHour')}</TableCell>
                        <TableCell>{i18n('entities.puesto.fields.laborHour')}</TableCell>
                        <TableCell>{i18n('entities.recurso.fields.profitMargin')}</TableCell>
                        <TableCell>HH Cliente</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Total Cliente</TableCell>
                        <TableCell align="right">{i18n('common.edit')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resourcesGrouped.perfilEmpleado.map((recurso) => (
                        <TableRow key={recurso.id}>
                          <TableCell>{recurso.descripcion}</TableCell>
                          <TableCell>{recurso.perfilEmpleado?.puesto.nombre}</TableCell>
                          <TableCell>{recurso.cantidad}</TableCell>
                          <TableCell>{recurso.total}</TableCell>
                          <TableCell>
                            {recurso.perfilEmpleado?.puesto.laborHour ? recurso.perfilEmpleado?.puesto.laborHour : 'N/A'}
                          </TableCell>
                          <TableCell>{recurso?.profitMargin}</TableCell>
                          {/* tambien multiplicar por el porcentaje, pero el porcentaje está en numeros enteros, por ejemplo 30, entonces hay que calclar el 30% de la multiplicacion de laborhour y cantidad */}
                          <TableCell>
                            {recurso?.cantidad !== undefined &&
                            recurso?.perfilEmpleado?.puesto?.laborHour !== undefined &&
                            recurso?.profitMargin !== undefined
                              ? recurso.cantidad * recurso.perfilEmpleado.puesto.laborHour +
                                (recurso.cantidad * recurso.perfilEmpleado.puesto.laborHour * recurso.profitMargin) / 100
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {recurso?.cantidad !== undefined && recurso?.total !== undefined
                              ? recurso.cantidad * recurso.total
                              : 'N/A'}
                          </TableCell>

                          <TableCell>
                            {recurso?.cantidad !== undefined &&
                            recurso?.perfilEmpleado?.puesto?.laborHour !== undefined &&
                            recurso?.profitMargin !== undefined
                              ? (recurso.cantidad * recurso.perfilEmpleado.puesto.laborHour +
                                  (recurso.cantidad * recurso.perfilEmpleado.puesto.laborHour * recurso.profitMargin) / 100) *
                                recurso.cantidad
                              : 'N/A'}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="small" onClick={() => handleOpenRecursoModal(recurso)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded style={{width: '100%', marginTop: 10}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{border: '1px solid #f0f0f0'}}
                aria-controls="panel-inventario"
                id="panel-inventario-header">
                <Typography>Inventario</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="inventario-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">{i18n('common.edit')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resourcesGrouped.inventario.map((recurso) => (
                        <TableRow key={recurso.id}>
                          <TableCell>{recurso.repuesto.descripcion}</TableCell>
                          <TableCell>{recurso.cantidad}</TableCell>
                          <TableCell>{recurso.cantidad}</TableCell>
                          <TableCell align="right">
                            <IconButton size="small" onClick={() => handleOpenRecursoModal(recurso)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded style={{width: '100%', marginTop: 10}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{border: '1px solid #f0f0f0'}}
                aria-controls="panel-servicios"
                id="panel-servicios-header">
                <Typography>Servicios</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="servicios-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Precio Hora</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell align="right">{i18n('common.edit')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resourcesGrouped.servicios.map((recurso) => (
                        <TableRow key={recurso.id}>
                          <TableCell>{recurso.servicio.descripcion}</TableCell>
                          <TableCell>{recurso.cantidad}</TableCell>
                          <TableCell>{recurso.servicio.costoHora}</TableCell>
                          <TableCell>{recurso.total}</TableCell>
                          <TableCell align="right">
                            <IconButton size="small" onClick={() => handleOpenRecursoModal(recurso)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.descripcion')}
                required={true}
                autoFocus
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <TipoPresupuestoAutocompleteFormItem
                name="tipoPresupuesto"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.tipoPresupuesto')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="montoPresupuesto"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.montoPresupuesto')}
                required={true}
              />
            </Grid>
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <CuentaContableAutocompleteFormItem
                name="cuentasContables"
                disabled={props.disabled}
                label={i18n(
                  'entities.presupuesto.fields.cuentasContables',
                )}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h2>Intervalos de Inicio y Fin</h2>
              <hr />
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="fechainicio"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.fechainicio')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="fechaFin"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.fechaFin')}
                required={false}
              />
            </Grid>
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <SwitchFormItem
                name="habilitado"
                disabled={props.disabled}
                label={i18n(
                  'entities.presupuesto.fields.habilitado',
                )}
              />
            </Grid> */}
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <CentroCostoAutocompleteFormItem  
                name="centrosCostosVinculados"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.centrosCostosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
          </Grid>
        </form>
        {!props.disabled && (
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        )}
      </FormProvider>

      {openRecursoModal && (
        <RecursoFormModal
          onClose={handleCloseRecursoModal}
          // onSuccess={handleRecursoModalSuccess}
          handleAddRecursoTemporal={handleAddRecurso}
          record={editingRecurso}
          presupuesto={props.record ? props.record : null}
        />
      )}
    </FormWrapper>
  );
}

export default PresupuestoForm;
