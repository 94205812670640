import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch} from 'react-redux';
import {i18n} from 'src/i18n';
import actions from 'src/modules/recurso/form/recursoFormActions';
import Errors from 'src/modules/shared/error/errors';
import RecursoForm from 'src/view/recurso/form/RecursoForm';

function RecursoFormModal(props) {
  const [saveLoading, setSaveLoading] = useState(false);
  const isEditing = Boolean(props.record);
  const handleSubmitProps = props.handleSubmitProps;
  const record = props.record;
  const presupuesto = props.presupuesto;
  const existePresupuesto = Boolean(!props.presupuesto);
  const handleAddRecursoTemporal = props.handleAddRecursoTemporal;

  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();

  const doSubmit = async (id, data) => {
    try {
      setSaveLoading(true);
      // const {id} = await RecursoService.create(data);
      // const record = await RecursoService.find(id);
      // props.onSuccess(record);

      console.log('presupuesto', presupuesto);

      if (isEditing) {
        dispatch(actions.doUpdate(id, data));
        data = {...data, id: id};
        handleAddRecursoTemporal(data);
      } else {
        if (existePresupuesto === false) {
          data = {...data, presupuesto: null};
        }

        const createdRecurso = await dispatch(actions.doCreate(data));
        handleAddRecursoTemporal(createdRecurso);
      }
    } catch (error) {
      Errors.handle(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const doClose = () => {
    return props.onClose();
  };

  return ReactDOM.createPortal(
    <Dialog open={true} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle
        style={
          {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          } as any
        }>
        <h2 style={{margin: 0}}>{i18n('entities.recurso.new.title')}</h2>
        <IconButton onClick={doClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RecursoForm
          saveLoading={saveLoading}
          onSubmit={doSubmit}
          onCancel={doClose}
          modal
          record={record}
          isEditing={isEditing}
          presupuesto={presupuesto}
        />
      </DialogContent>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default RecursoFormModal;
