
import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/historialInventario/importer/historialInventarioImporterSelectors';
import HistorialInventarioService from 'src/modules/historialInventario/historialInventarioService';
import fields from 'src/modules/historialInventario/importer/historialInventarioImporterFields';
import { i18n } from 'src/i18n';

const historialInventarioImporterActions = importerActions(
  'HISTORIALINVENTARIO_IMPORTER',
  selectors,
  HistorialInventarioService.import,
  fields,
  i18n('entities.historialInventario.importer.fileName'),
);

export default historialInventarioImporterActions;